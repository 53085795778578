<template>
  <div class="slider">
    <div class="slider__wrapper">
      <swiper
        class="slider__element"
        :slides-per-view="slidesPerView"
        :slides-per-group="slidesPerGroup"
        :initial-slide="initialSlide"
        :auto-height="autoHeight"
        :loop="loop"
        :pagination="pagination"
        :breakpoints="breakpoints"
        :autoplay="autoplay"
        :space-between="0"
        :modules="[Navigation, Autoplay, Pagination]"
        @swiper="onSwiper"
        @autoplay="onUpdate"
        @update="onUpdate"
        @resize="onUpdate"
        @slide-change="onUpdate"
      >
        <swiper-slide
          v-for="(slide, index) of slides"
          :key="index"
          class="slider__slide"
        >
          <component :is="slide" />
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { SwiperOptions, Swiper as SwiperType } from 'swiper/types'
import { Navigation, Pagination, Autoplay } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { watch, computed, useSlots, ref } from 'vue'
import { getChildNodes } from 'src/utils/helpers'
import type { VNode } from 'vue'
import { useI18n } from 'src/composables/useI18n'

export type SliderRootState = {
  realIndex: number
  totalSlides: number
  slidesPerView: number | 'auto'
  allowSlidePrev: boolean
  allowSlideNext: boolean
  slideNext: () => void
  slidePrev: () => void
  update: () => void
}

interface Emits {
  (e: 'state', value: SliderRootState): void
}

type Props = Pick<
  SwiperOptions,
  'initialSlide'
  | 'autoHeight'
  | 'loop'
  | 'slidesPerView'
  | 'slidesPerGroup'
  | 'breakpoints'
  | 'pagination'
  | 'autoplay'
>

const props = withDefaults(defineProps<Props>(), {
  autoHeight: false,
  slidesPerView: 'auto',
  adaptiveSpaceBetween: false,
  loop: false,
  pagination: false,
  autoplay: false
})

const emit = defineEmits<Emits>()

const slots = useSlots()
const { locale } = useI18n()

const swiperInstance = ref<SwiperType | null>(null)
const state = ref<SliderRootState | null>(null)

const slides = computed(() => {
  const slot = slots.default?.()
  return getChildNodes(slot) as VNode[]
})

const loop = computed(() => {
  return props.loop && slides.value.length > 1
})

const pagination = computed(() => {
  return props.pagination ? { clickable: true } : false
})

const autoplay = computed<NonNullable<SwiperOptions['autoplay']>>(() => {
  if (!props.autoplay) return false
  return { delay: 5000 }
})

function getState(swiper: SwiperType): SliderRootState {
  return {
    realIndex: swiper.realIndex,
    totalSlides: swiper.slides.length,
    slidesPerView: swiper.params.slidesPerView || 0,
    allowSlidePrev: !swiper.isBeginning || !!swiper.params.loop,
    allowSlideNext: !swiper.isEnd || !!swiper.params.loop,
    slideNext: () => swiper.slideNext(),
    slidePrev: () => swiper.slidePrev(),
    update: swiper.update
  }
}

function onSwiper(swiper: SwiperType) {
  swiperInstance.value = swiper
  onUpdate(swiper)
}

function onUpdate(swiper: SwiperType) {
  const newState = getState(swiper)
  state.value = newState
  emit('state', newState)
}

watch(locale, () => {
  swiperInstance.value?.update()
}, { flush: 'post' })
</script>

<style lang="scss" scoped>
.slider {
  --space-between: var(--grid-gutter);
  --wrapper-py: calc(var(--space-between) / 2);
  --wrapper-px: var(--wrapper-py);
  display: flex;
  position: relative;
  &__wrapper {
    width: calc(100% + var(--wrapper-px) * 2);
    margin: calc(var(--wrapper-py) * -1) calc(var(--wrapper-px) * -1);
    padding: var(--wrapper-py) var(--wrapper-px);
    overflow: hidden;
  }
  &__element {
    margin: 0 calc(var(--space-between) / -2);
    overflow: visible;
    position: relative;
    z-index: 1;
  }

  &:deep(.swiper-slide) {
    box-sizing: border-box;
    width: auto;
    height: auto;
    padding: 0 calc(var(--space-between) / 2);
    & > * {
      height: 100%;
    }
  }

  @media (max-width: $breakpoint-xs-max) {
    --wrapper-px: var(--grid-px);
  }
}

.slider:deep(.swiper-pagination) {
  display: flex;
  justify-content: center;
  margin-top: var(--lg);
  gap: 10px;

  .swiper-pagination-bullet {
    flex: 1 1 auto;
    max-width: 20px;
    height: 4px;
    border-radius: 2px;
    cursor: pointer;
    background-color: var(--theme-color-muted-medium);
    transition: background-color var(--trs-1);
    &-active {
      background-color: var(--theme-color-primary);
    }
  }

  &.swiper-pagination-lock {
    display: none;
  }
}
</style>

<style lang="scss">
@import 'swiper/css';
</style>
