import type { VNode, VNodeChild, VNodeArrayChildren } from 'vue'

export function getChildNodes(node: VNode[] | VNodeArrayChildren | undefined): (VNode | VNodeChild)[]  {
  if (!node) return []

  let array: (VNode | VNodeChild)[] = []

  for (let child of node) {
    if (Array.isArray(child)) {
      array = [...array, ...getChildNodes(child)]
    } else if (typeof child === 'object') {
      const arr = child?.children
      if (child?.type.toString().includes('Symbol') && Array.isArray(arr)) {
        array = [...array, ...getChildNodes(arr)]
      } else {
        array.push(child)
      }
    }
  }

  return array
}
