<template>
  <div v-if="showControls" class="slider-controls">
    <app-button
      :icon="iconChevronLeft"
      :color="slider.allowSlidePrev ? 'light' : 'transparent'"
      :disabled="!slider.allowSlidePrev"
      :muted="overlay"
      square
      @click="slider.slidePrev()"
    />
    <app-button
      :icon="iconChevronRight"
      :color="slider.allowSlideNext ? 'light' : 'transparent'"
      :disabled="!slider.allowSlideNext"
      :muted="overlay"
      square
      @click="slider.slideNext()"
    />
  </div>
</template>

<script lang="ts" setup>
import iconChevronRight from 'assets/icons/light/chevron-right.svg?raw'
import iconChevronLeft from 'assets/icons/light/chevron-left.svg?raw'
import { type SliderRootState } from 'src/components/slider/SliderRoot.vue'
import { computed } from 'vue'

interface Props {
  slider: SliderRootState
  overlay?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  overlay: false
})

const showControls = computed(() => props.slider.allowSlidePrev || props.slider.allowSlideNext)
</script>

<style lang="scss" scoped>
.slider-controls {
  display: flex;
  gap: var(--grid-gutter);
}
</style>
